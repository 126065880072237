<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>

    <div id="app-content">
      <div class="app-nav" v-if="isSharePage">
        <div class="icon" @click="goPage('/')">
          <van-icon name="arrow-left" />
        </div>
        <div class="title">{{ shoptext.shopName }}</div>
      </div>
      <!--    商铺信息-->
      <div class="page-item">
        <div class="shop-goto" @click="perivewimg">
          <span class="f36 left ">
            <span class="page-icon2 shop"></span>
            <strong class="txt-ellipsis shopName margin-l1">{{ shoptext.shopName }}</strong>
            <i class="el-icon-arrow-right"></i>
          </span>
          <span class="goto-img  f30">
            <img v-if="shoptext.channelOutId === 'C004' || shoptext.channelOut === 'C004'" class="margin-lr2"
              src="../../assets/home/elm.png">
            <img v-if="shoptext.channelOutId === 'C003' || shoptext.channelOut === 'C003'" class="margin-lr2"
              src="../../assets/home/mt.png">
            <span v-if="shoptext.distance">约{{ shoptext.distance | distanceFormat }}</span>
          </span>
        </div>

        <div class="shopInfo">
          <img class="albumPics" :src="shoptext.albumPics || shoptext.thumbPics" @click="perivew(shoptext)" />
          <div class="info margin-l2 flex-1">
            <div class=""><strong>{{ shoptext.spuTitle }}</strong></div>
            <div class="f32">
              <span class="plus margin-r2"> Plus会员:满{{ shoptext.price }}返{{ shoptext.vipProfit }}</span>
              <span class="noPlus">非会员:满{{ shoptext.price }}返{{ shoptext.profit }}</span>
            </div>
          </div>
        </div>

        <div class="address f34" @click="perivewimg()">地址：{{ shoptext.address }}</div>
      </div>

      <!--    试吃金步骤-->
      <!-- <ObtainStep /> -->

      <!--    试吃活动  -->
      <div class="activeInfo page-item f36">
        <div class="title"><strong>试吃活动</strong></div>
        <div class="content">
          <ul>
            <li>
              <div class="grey margin-lr2">
                <span class="page-icon2 channel"></span>
                <span class="vertical-middle margin-l1">平台</span>
              </div>
              <div class="margin-lr2">{{ shoptext.channelOutName }}</div>
            </li>
            <li>
              <div class="grey margin-lr2">
                <span class="font">返</span>
                <span class="vertical-middle margin-l1">满返</span>
              </div>
              <div class="margin-lr2">实付满 <span class="red">{{ shoptext.price }}</span> 返 <span class="red">{{ realProfit
              }}</span> </div>
            </li>
            <li v-if="shoptext.commentFlag === 'N' || trying === 'Y'" class="remark flex flex-ai-c">
              <div class="grey margin-lr2">
                <span class="font">类</span>
                <span class="vertical-middle margin-l1">类型</span>
              </div>
              <div class="flex-1 margin-lr2 font-bold red">
                {{ commentFlag === 'Y' ? '本单无需品鉴意见' : shoptext.detailTitle }}
              </div>
            </li>
            <li v-if="shoptext.commentFlag === 'N' || trying === 'Y'" class="remark flex flex-ai-c">
              <span class="font margin-l2">注</span>
              <div class="grey margin-r2  margin-l1">备注</div>
              <div class="flex-1 margin-lr2" v-if="commentFlag === 'N'">{{ shoptext.detailDesc | emptyFilter }}</div>
              <div class="flex-1 margin-lr2" v-if="commentFlag === 'Y'">{{ shoptext.commentDesc | emptyFilter }}</div>
            </li>
            <li class="bottom">
              <div>剩余：{{ shoptext.stock || 0 }}</div>
              <div>
                <div v-if="trying === 'N'" class="mainBut f38" @click="signtry()">报名试吃</div>
                <div v-if="trying === 'Y'" class="mainBut f38 redBut" @click="cancel()">取消报名</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--    报名后我的试吃 -->
      <div class="steps page-item" v-if="trying === 'Y'">
        <div class="title">
          <strong>试吃步骤</strong>
          <div class="fr f36 mainColor" @click="copy(shoptext.shopName)">复制店名</div>
        </div>
        <div class="content">
          <div class="try">
            <div class="stepsNumber">
              <span class="first-scole mainBgColor"></span>
              <div class="vertical"></div>
            </div>
            <div class="flex-1">
              <div><strong>第1步 前往店铺下单</strong></div>
            </div>
            <div class="mainBut right-button" @click="perivewimg">
              <span>去点餐</span>
              <img src="../../assets/home/elm.png" v-if="shoptext.channelOutId === 'C004'" />
              <img src="../../assets/home/mt.png" v-if="shoptext.channelOutId === 'C003'" />
            </div>
          </div>
          <div class="try">
            <div class="stepsNumber">
              <div class="first-scole mainBgColor">
                <div></div>
              </div>
              <div class="vertical"></div>
            </div>
            <div class="flex-1">
              <div><strong>第2步 上传相关截图</strong></div>
              <div class="margin-t1 f34 grey">审核通过后获得试吃金</div>
            </div>
            <div class="mainBut" @click="unloadimg()">上传截图</div>
          </div>
        </div>
      </div>

      <div class="page-item fans">
        <div class="page-icon fans"></div>
        <div class="margin-lr2 flex-1 f36">
          <div><strong>粉丝专属福利群</strong></div>
          <div class="margin-t1 grey6 f32">更多优惠与福利等您加入~</div>
        </div>
        <div class="mainBut-plain f38" @click="jionGroup()">点击加入</div>
      </div>

      <!--    活动规则-->
      <ActiveRule />
    </div>

    <selfTry ref="selfTry"></selfTry>
  </div>
</template>

<script>
import { tfoodApi, mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'
import { Dialog } from 'vant'
import selfTry from './compents/selfTry.vue'
import ObtainStep from '@/components/ObtainStep.vue'
import ActiveRule from './compents/ActiveRule.vue'
import { settime, dateFormat } from '../../utils/utils'
import thechangePhone from '@/components/changePhone.js'
import wx from 'weixin-js-sdk'

export default {
  name: 'apply',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    selfTry,
    ObtainStep,
    ActiveRule,
  },
  data() {
    return {
      shoptext: {},
      shopId: '',
      spuId: '',
      trying: 'N',
      lasttime: null,
      timeOut: null,
      loading: false,
      groupUrl: '',
      isSharePage: false,
      commentFlag: 'N', // N: 需要评论
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions', 'isApp', 'agentConfig', 'themesMap', 'agentId', 'location', 'address']),
    realProfit() {
      return (
        (this.custMsg.vipPlus === 1
          ? this.shoptext.vipProfit
          : this.shoptext.profit) || this.shoptext.realProfit
      )
    },
  },
  filters: {
    distanceFormat(val) {
      val = Number(val)
      if (val < 1000) {
        return val + 'm'
      } else {
        let num = val / 1000
        return num.toFixed(1) + 'km'
      }
    },
    emptyFilter(val) {
      return val ? val : '--'
    }
  },
  watch: {
    address() {
      this.$nextTick().then(() => {
        this.getShopInfo()
      })
    },
  },
  created() {
    this.trying = this.$route.query.trying || 'N'
    this.shopId = this.$route.query.shopId
    this.spuId = this.$route.query.spuId
    this.isActivity = this.$route.query.isActivity || 'N'
    let shopInfo = sessionStorage.getItem('shoptext')
    if (shopInfo) {
      this.shoptext = JSON.parse(shopInfo)
      this.weChatShopShare()
    } else {
      this.isSharePage = true
      setTimeout(() => {
        this.getShopInfo()
      }, 1000)
    }
    this.getGroupUrl()
  },
  destroyed() {
    clearTimeout(this.timeOut)
    this.weChatShare()
  },
  methods: {
    ...mapActions('user', ['getOrderNum', 'weChatShare']),

    // 获取店铺信息
    getShopInfo() {
      let params = {
        trxCode: 'MS108',
        shopId: this.shopId,
        spuId: this.spuId,
        isActivity: this.isActivity,
        agentId: this.agentId,
        page: 1,
        pageSize: 100,
        distance: '15000',
        lng: this.location.longitude,
        lat: this.location.latitude,
      }
      tfoodApi(params).then(res => {
        if (res.foodGoodList) {
          this.shoptext = res.foodGoodList[0]
          sessionStorage.setItem('shoptext', JSON.stringify(this.shoptext))
          this.weChatShopShare()
        } else {
          this.$toast('该店铺已超出配送距离')
          this.$router.push({ path: '/' })
        }
      })
    },

    //用sdk预览数据
    perivewimg() {
      // 非app环境
      if (!this.isApp) {
        if (this.shoptext.channelOutLink) {
          window.location.href = this.shoptext.channelOutLink
        } else {
          var urls = window.encodeURI(this.shoptext.channelOutApplet)
          this.$refs.selfTry.getImg(true, urls)
        }
      }

      // app环境
      if (this.isApp) {
        if (this.shoptext.channelOutLink) {
          // window.top.location.href = this.shoptext.channelOutLink
          uni.postMessage({
            data: {
              type: 'openUrlOnBrowser',
              url: this.shoptext.channelOutLink
            }
          })
        } else {
          this.$toast('请复制店名到店下单~')
        }
      }
    },
    perivew(shoptext) {
      let url = shoptext.albumPics || shoptext.thumbPics
      this.$refs.selfTry.getImg(true, url)
    },


    // 报名试吃
    signtry() {
      if (this.loading) return
      // 未绑定手机号
      if (this.custMsg.phoneUser === "N") {
        thechangePhone()
        return
      }

      let checkTime = this.checkTime(this.shoptext.businessTime)
      if (!checkTime) {
        this.$toast('未到店铺抢单时间, 敬请期待~')
        return
      }

      this.loading = true
      let params = {
        trxCode: 'MS103',
        // openId: this.custMsg.openIdMp,
        shopId: this.shoptext.shopId,
        spuId: this.shoptext.spuId,
        skuId: this.shoptext.skuId,
        activityFlag: this.shoptext.activityFlag,
        creatTime: new Date().getTime()
      }
      tfoodApi(params).then((res) => {
        this.loading = false
        if (res.orderId) {
          this.orderId = res.orderId
          this.shoptext.logId = res.orderId
          this.shoptext.commentFlag = res.commentFlag
          sessionStorage.setItem('shoptext', JSON.stringify(this.shoptext))
          this.trying = 'Y'
          this.getOrderNum()

          // 商家要求展示
          this.commentFlag = res.commentFlag
          let detailDesc = res.commentFlag === 'Y' ? '本单无需品鉴意见' : this.shoptext.detailDesc
          let orderCancelTime = this.agentConfig.ORDER_CANCEL_TIME || '180'
          const bodyClass = document.body.className
          let theme = this.themesMap.find(v => v.value === bodyClass)
          Dialog.alert({
            message: `请在${orderCancelTime}分钟内下单并提交资料，否则名额将会被自动释放。`,
            // message: `请在${orderCancelTime}分钟内下单并提交资料，否则名额将会被自动释放。\n\n商家信息: ${detailDesc}`,
            messageAlign: 'left',
            theme: 'round',
            confirmButtonColor: theme.color,
            theme: 'round-button',
          })
        } else {
          this.trying = 'N'
          this.$toast(res.rspMsg)
        }
      })
    },

    // 检查当前时间是否在抢单时间内
    checkTime(val) {
      if (!val) return
      let nowVal = dateFormat(new Date(), 'HHMM')
      let startVal = val.split('-')[0].replace(':', '')
      let endVal = val.split('-')[1].replace(':', '')
      if (startVal >= endVal) {
        // 存在跨天情况
        return (startVal <= nowVal && nowVal < 2400) || (0 <= nowVal && nowVal < endVal)
      } else {
        return startVal <= nowVal && nowVal < endVal
      }
    },

    //取消订单
    cancel() {
      let params = {
        trxCode: 'MS106',
        logId: this.shoptext.logId,
      }
      tfoodApi(params).then((res) => {
        if (res.rspMsg) {
          this.$toast.fail(res.rspMsg)
        } else {
          this.$toast.success('取消成功')
          this.trying = 'N'
          this.getOrderNum()
          // this.$router.push({
          //   name: 'Home',
          // })
        }
      })
    },

    // 微信店铺分享
    weChatShopShare() {
      const shareUrl = `${this.agentConfig.SELF_DOMAIN_USER}/?agentId=${localStorage.getItem('agent')}&parentId=${this.custMsg.custId}&trying=N#/apply?&spuId=${this.spuId}&shopId=${this.shopId}&isActivity=${this.isActivity}`
      const imgUrl = this.shoptext.avatar
      const title = this.agentConfig.bwcName
      const desc = this.shoptext.shopName
      // 分享给朋友及QQ
      wx.updateAppMessageShareData({
        title,
        desc,
        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl,
      })
      // 分享给朋友及QQ (旧版,即将废弃)
      wx.onMenuShareAppMessage({
        title,
        desc,
        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl,
      })
      // 分享到朋友圈及 QQ 空间
      wx.updateTimelineShareData({
        title,
        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl,
      })
    },

    // 获取客服信息--- 福利群二维码
    getGroupUrl() {
      mallApi({
        trxCode: 'XF906',
      }).then((res) => {
        this.groupUrl = res.wechatImgLink
      })
    },

    // 加福利群
    jionGroup() {
      if (!this.groupUrl) {
        this.$toast('平台未设置')
        return
      }
      this.$refs.selfTry.getImg(true, this.groupUrl)
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path })
    },

    // 上传截图
    unloadimg() {
      this.$router.replace({
        path: '/screenshot',
      })
    },

    copy(val) {
      this.$copyText(val).then((res) => {
        this.$toast.success(`复制成功, 请前往${this.shoptext.channelOutName}下单`)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page-item {
  .title {
    font-size: 3.8vw;
    margin-bottom: 3vw;
  }
}

.shopInfo {
  display: flex;

  .albumPics {
    width: 20vw;
    height: 20vw;
    object-fit: cover;
    border-radius: 2vw;
  }

  .info {
    .shop-other {
      margin-top: 1vw;
      font-size: 3.4vw;
    }
  }

  .plus,
  .noPlus {
    margin-top: 2vw;
  }
}

.shop-goto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3vw;
  padding-bottom: 3vw;
  border-bottom: 1px solid #efefef;

  .left {
    display: flex;
    align-items: center;
  }

  .shopName {
    max-width: 46vw;
    display: inline-block;
  }

  .goto-img>img {
    width: 16px;
    vertical-align: middle;
  }

  .page-icon2.shop {
    width: 14px;
    height: 13px;
    background-position: -289px -156px;
    transform: scale(1.2);
    margin-right: 1vw;
  }
}

.address {
  padding: 1vw 2vw;
  border-radius: 4px;
  margin-top: 3vw;
  background: #f3f3f3;
  color: #666;
}

.steps.page-item {
  .try {
    display: flex;
    align-items: flex-start;
  }

  .stepsNumber {
    width: 6vw;
    text-align: center;

    .first-scole {
      display: inline-block;
      width: 2.7vw;
      height: 2.7vw;
      border-radius: 50%;
    }

    .vertical {
      width: 3px;
      height: 10vw;
      border-right: 3px dashed #eeeeee;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }

  .right-button {
    img {
      width: 5vw;
      margin-left: 1vw;
      vertical-align: middle;
    }
  }
}

.activeInfo.page-item {
  ul li {
    display: flex;
    margin-top: 2vw;
  }

  li.bottom {
    justify-content: space-between;
    margin-top: 3vw;
  }

  .font {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    background: #ff5b50;
    color: #fff;
    border-radius: 2px;
    vertical-align: middle;
    text-align: center;
  }

  .page-icon2.channel {
    width: 16px;
    height: 16px;
    background-position: -311px -154px;
    vertical-align: middle;
  }
}

.page-item.fans {
  display: flex;
  align-items: center;

  .page-icon.fans {
    width: 54px;
    height: 54px;
    background-position: -251px -75px;
  }
}

.joinGroup {
  text-align: center;
}
</style>
