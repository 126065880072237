<template>
  <div class="page-item obtain-step">
    <div class="title">
      <strong>如何获得试吃金</strong>
      <span class="fr mainColor f36" @click="$router.push('/detailed')">查看详细步骤</span>
    </div>
    <ul class="content">
      <li>
        <div class="page-icon step1"></div>
        <div>报名试吃</div>
      </li>
      <!-- <li class="arrow">
        <div class="page-icon arrow"></div>
      </li> -->
      <div class="page-icon arrow"></div>

      <li>
        <div class="page-icon step2"></div>
        <div>点外卖</div>
      </li>
      <div class="page-icon arrow"></div>
      <li>
        <div class="page-icon step3"></div>
        <div>上传截图</div>
      </li>
      <div class="page-icon arrow"></div>
      <li>
        <div class="page-icon step4"></div>
        <div>试吃金到账</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
  mounted() { },
  methods: {},
}
</script>

<style lang='less' scoped>
.page-item {
  .title {
    font-size: 3.8vw;
    margin-bottom: 3vw;
    > * {
      vertical-align: middle;
    }
    > img {
      margin-right: 2vw;
      width: 4vw;
    }
    > strong {
      color: #333;
    }
  }
}
.obtain-step {
  .content {
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    font-size: 3vw;

    li + li {
      margin-left: 2vw;
    }

    img {
      width: 13vw;
      margin-bottom: 1vw;
    }

    .arrow img {
      width: 4vw;
      margin-top: 5vw;
    }
    .page-icon {
      width: 48px;
      height: 48px;
    }
    .page-icon.step1 {
      background-position: -251px -14px;
    }
    .page-icon.step2 {
      background-position: -312px -14px;
    }
    .page-icon.step3 {
      background-position: -374px -14px;
    }
    .page-icon.step4 {
      background-position: -435px -14px;
    }
    .page-icon.arrow {
      width: 8px;
      height: 14px;
      background-position: -78px -123px;
    }
  }
}
</style>