<template>
  <div class="page-item active-rule">
    <div class="title">
      <strong>试吃规则</strong>
      <span class="fr grey f32" @click="$router.push('/puertion')">了解更多规则</span>
    </div>
    <div class="active-text" v-if="!agentConfig.ORDER_RULE_WORD">
      <div>1.报名成功后需到对应外卖平台下单，实付金额必须满足商品要求，下单并确认收货后根据 试吃要求截取和上传订单相关截图，等待平台审核。</div>
      <div>2.用户在报名成功后需在<span class="red">{{orderCancelTime}}分钟内上传订单相关截图</span>
        ，若超时未上传截图或取消报名，则试吃失败，若{{orderCancelTime}}分钟内上传截图，平台会在{{orderFinishTime}}小时内审核（节假日顺延）。
      </div>
      <div>3.用户上传的截图自首次提交审核后{{orderFinishTime}}小时内未审核通过，则订单终止受理，不在接受纠正后的截图·。</div>
      <div>4.平台审核未通过不发放试吃金，审核通过可领取试吃金。</div>
      <!-- <div>5.用户一个平台一个店铺一天只能报名一单，一个平台一个店铺可最多联系报名2天，一个平台不同店铺不限制报名次数。</div> -->
      <div>5.用户一次只能抢购{{orderNum}}个试吃名额，报名的试吃上传截图提交审核后即可继续报名，未上传截图提交审核无法抢购新的试吃名额。</div>
    </div>
    <div v-else class="active-text" v-html="agentConfig.ORDER_RULE_WORD" style="white-space: pre-wrap;"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ActiveRule',
  data() {
    return {
      orderCancelTime: '180',
      orderFinishTime: '48',
      orderNum: '1',
    }
  },
  computed: {
    ...mapState('user', ['agentConfig']),
  },
  created() {
    if (this.agentConfig.ORDER_CANCEL_TIME) {
      this.orderCancelTime = this.agentConfig.ORDER_CANCEL_TIME
    }
    if (this.agentConfig.ORDER_FINISH_TIME) {
      this.orderFinishTime = this.agentConfig.ORDER_FINISH_TIME
    }
    if (this.agentConfig.LOCK_ORDER_NUM) {
      this.orderNum = this.agentConfig.LOCK_ORDER_NUM
    }
  },
  methods: {},
}
</script>

<style lang='less' scoped>
.page-item {
  .title {
    font-size: 3.8vw;
    margin-bottom: 2vw;
  }
}
/deep/ .active-text {
  color: #555;

  ul,
  ol {
    list-style: decimal;
    list-style-position: inside;
  }

  > div {
    margin-bottom: 2vw;
  }
}
</style>